import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL;

class BillingService {
  async import(data) {
    const config = {
      headers: authHeader()
    };

    config.headers['Content-Type'] = 'multipart/form-data';

    const response = await axios
      .post(`${API_URL}billing/import`, data, config);

    return response.data;
  }

  async billingContracts(data) {
    const config = {
      headers: authHeader()
    };

    const response = await axios
      .post(`${API_URL}billing/contracts`, data, config);

    return response.data;
  }
  
  async addBilling(data) {
    const config = {
      headers: authHeader()
    };

    const response = await axios
      .post(`${API_URL}billing/update`, data, config);

    return response.data;
  }
}

export default new BillingService();